.app__aside a {
    color: #38393b;
    display: block;
    width: 110%;
    line-height: 60px;
    text-decoration: none;
    padding-left: 10px;
    box-sizing: border-box;
    transition: 0.5s;
    transition-property: background;
}

.app__aside a:hover {
    background: #8C2804;
    color: white;
}

.app__aside span {
    font-family: 'FuturaPTBook';
    font-size: large;
    padding-left: 10px;
}