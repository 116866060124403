.dash {
    background-color: white;
    max-width: 400px;
    width: 100%;
    /* width: 90vw; */
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 20px;

}

.parent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(3, 1fr); */
    /* grid-template-rows: repeat(3, 1fr); */
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    /* padding: 20px; */
}

.div1 {
    order: 1;
    max-width: 820px;
    width: 100%;
}

.div2 {
    order: 2;
}

.div3 {
    order: 3;
}

.div4 {
    order: 4;
}

.div5 {
    order: 5;
}

.div6 {
    order: 6;
}

.div7 {
    order: 7;
}



.errorMsg {
    color: #8C2804;
    font-size: 16px;
    padding-top: 5px;
}

.dash__photoUrl {
    /* display: flex; */
    width: 100%;
    object-fit: contain;
    /* max-width: 480px; */

}

.dash_title {
    width: 15em;
    /* text-overflow: ellipsis; */

}

.dash_action {
    width: 20em;

}

.cropped {
    height: 200px;
    overflow: hidden;
}

.dash__overflow {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    width: 22em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 35px;
}

.dash__headerLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashFeed {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    max-width: 820px;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 20px;

}

.dash_category {
    color: #CC8A05;

}

h3 {
    font-family: 'FuturaPTMedium';
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}


.page_title {
    margin-left: 1.5%;
}

.dash__body {
    margin-top: 10px;
    font-family: 'FuturaPTBook';
    display: flex;
    flex-direction: column;
    font-size: 18px;
    white-space: pre-line;
}

.dash__body p {
    text-align: left;
    line-height: 20px;
}

.dash__link {
    display: flex;
}

.createDash {
    background-color: white;
    max-width: 480px;
    /* width: 90vw; */
    padding: 16px;
    border-radius: 15px;
}

.section__half {
    margin-top: 20px;
    float: left;
    width: 50%;
    margin-left: 1.5%;
}

.section {
    /* margin-top: 20px; */
    /* float: left; */
    /* width: 30%; */
    margin-left: 1.5%;
}


.myButton {
    background-color: #fac554;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    color: #8C2804;
    font-family: 'FuturaPTBook';
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    text-decoration: none;
}

.myButton:hover {
    background-color: #CC8A05;
    color: beige;
}

.myButton:active {
    position: relative;
    top: 1px;
}

.table {
    background-color: white;
    border-collapse: collapse;
    font-size: 18px;
    border-radius: 10px;
    /* box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2), -1px, -1px, 8px rgba(0, 0, 0, 0.2); */
}

tbody tr {
    transition: all .2s ease-in;

    /* cursor: pointer; */
}

tbody tr:hover {
    background-color: #f5f5f5;
    transform: scale(1.02);
}

thead {
    background-color: #8C2804;
    color: #fff;
}

.table th,
td {
    /* padding: 12px; */
    text-align: left;
    /* border-bottom: 1px solid #ddd; */
}

h2 {
    font-family: 'FuturaPTDemi';
}