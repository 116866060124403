.header {
    background: white;
    width: 100%;
    min-height: 10vh;
    padding: 20px;

}

.header img {
    max-width: 150px;
}

.centerBtn {
    flex-direction: column;
    justify-content: flex-start;
    float: right;
    margin-top: 10px;

}

.userLogo {
    color: #CC8A05;
    font-size: 25px;

}

.logout {
    color: #8C2804;
    font-size: 30px;

}

.redbar {
    height: 8px;
    width: 100%;
    background-color: #8C2804;
}