body {
  /* position: relative; */
  height: 100%;
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'FuturaPTBook';
  src: local('FuturaPTBook'), url(./assets/fonts/FuturaPTBook.otf) format('opentype');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e5e3;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'FuturaPTBook';
  src: local('FuturaPTBook'), url(./assets/fonts/FuturaPTBook.otf) format('opentype');
}

@font-face {
  font-family: 'FuturaPTDemi';
  src: local('FuturaPTDemi'), url(./assets/fonts/FuturaPTDemi.otf) format('opentype');
}

@font-face {
  font-family: 'FuturaPTMedium';
  src: local('FuturaPTMedium'), url(./assets/fonts/FuturaPTMedium.otf) format('opentype');
}

.app__section {
  float: right;
  width: 90%;
  background-color: rgb(235, 233, 233);
  min-height: 80vh;
}

.app__aside {
  float: left;
  width: 10%;
  min-height: 80vh;

}

.aside_background {
  background-image: url(./assets/Fog.jpg);
  background-color: whitesmoke;
  background-blend-mode: screen;
  min-height: 80vh;
  background-size: cover;

}

.app__footer {
  /* clear: both; */
  background-color: white;
  position: relative;
  margin-top: -10vh;
  clear: both;
  left: 0;
  bottom: 0;
  height: 10vh;
  width: 100%;
  overflow: hidden;

}