.user {
    background-color: white;
    max-width: 480px;
    width: 100%;
    /* width: 90vw; */
    padding: 50px;
    border-radius: 15px;
    margin-bottom: 20px;

}

.user__header {}

/* .user__center {} */

.user__photoUrl {
    /* display: flex; */
    width: 100%;
    object-fit: contain;
    /* max-width: 480px; */

}

.user_title {
    width: 13em;
    /* text-overflow: ellipsis; */

}

.user_action {
    width: 12em;

}

.user__overflow {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    width: 15em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal
}

.user_action {
    width: 10em;
}

.user__headerLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.feed {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.user_category {
    color: #CC8A05;

}

h3 {
    font-family: 'FuturaPTMedium';
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}


.page_title {
    margin-left: 1.5%;
}

.user__body {
    margin-top: 10px;
    font-family: 'FuturaPTBook';
    display: flex;
    flex-direction: column;
    font-size: 18px;
    white-space: pre-line;
}

.user__body p {
    text-align: left;
    line-height: 20px;
}

.user__link {
    display: flex;
}

.createUser {
    background-color: white;
    max-width: 480px;
    /* width: 90vw; */
    padding: 16px;
    border-radius: 15px;
}

.section__half {
    margin-top: 20px;
    float: left;
    width: 50%;
    margin-left: 1.5%;
}

.section {
    /* margin-top: 20px; */
    /* float: left; */
    /* width: 30%; */
    margin-left: 1.5%;
}


.myButton {
    background-color: #fac554;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    color: #8C2804;
    font-family: 'FuturaPTBook';
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    text-decoration: none;
}

.myButton:hover {
    background-color: #CC8A05;
    color: beige;
}

.myButton:active {
    position: relative;
    top: 1px;
}

.table {
    background-color: white;
    border-collapse: collapse;
    font-size: 18px;
    border-radius: 10px;
    /* box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.2), -1px, -1px, 8px rgba(0, 0, 0, 0.2); */
}

tbody tr {
    transition: all .2s ease-in;

    /* cursor: pointer; */
}

tbody tr:hover {
    background-color: #f5f5f5;
    transform: scale(1.02);
}

thead {
    background-color: #8C2804;
    color: #fff;
}

.table th,
td {
    /* padding: 12px; */
    text-align: left;
    /* border-bottom: 1px solid #ddd; */
}

h2 {
    font-family: 'FuturaPTDemi';
}